import './style.css'
import ReactDOM from 'react-dom/client'
import {extend, createRoot, events, Canvas, useThree, useFrame} from '@react-three/fiber'
import Experience from './Experience.jsx'
import {useEffect, useLayoutEffect, useMemo, useRef} from "react";
import {PerspectiveCamera} from "@react-three/drei";
import * as THREE from "three";
import {SVGRenderer} from 'three-stdlib'
import { AsciiEffect } from 'three-stdlib'

extend(THREE)


// import CameraControls from 'camera-controls'
import {CircleTimeTest} from "./CircleTimeTest.jsx";
import {CircleTimeTest as CircleTimeTest003} from "./CircleTimeTest003.jsx";
import {CircleTimeTest as CircleTimeTest002} from "./CircleTimeTest002.jsx";
import {CircleTimeTest as CircleTimeTest001} from "./CircleTimeTest001.jsx";
import {WebGLRenderer} from "three";
// CameraControls.install({ THREE })

const root = createRoot(document.querySelector('#root'))

const App = () => {
    return (<>
        {/*<Experience  />*/}
        <CircleTimeTest/>
        {/*<AsciiRenderer fgColor="white" bgColor="black" />*/}
        {/*<CircleTimeTest003  position={[ - 25, 0, 0 ]}/>*/}
        {/*<CircleTimeTest002  position={[ - 50, 0, 0 ]}/>*/}
        {/*<CircleTimeTest001  position={[ - 75, 0, 0 ]}/>*/}
    </>)
}

window.addEventListener('resize', () => {
    root.configure({
        // Inject renderer
        gl: (canvas) => {
             //const gl = new SVGRenderer()
            const gl = new WebGLRenderer({alpha:true})

            canvas.appendChild(gl.domElement)
            return gl
        }, // Use events, too ...
        // events,
        camera: {
            fov: 45, near: 0.1, far: 200, position: [-4, 3, 6],
        }, size: {width: window.innerWidth, height: window.innerHeight,}
    })
    root.render(<App/>)

})
window.dispatchEvent(new Event('resize'))

// root.render(
//     <Canvas
//         camera={ {
//             fov: 45,
//             near: 0.1,
//             far: 200,
//             position: [ - 4, 3, 6 ]
//         } }
//         // onPointerMissed={ () => { console.log('You missed!') } }
//     >
//         {/*<PerspectiveCamera makeDefault position={[ - 4, 3, 6 ]} />*/}
//         <App/>
//     </Canvas>
// )

function AsciiRenderer({
                           renderIndex = 1,
                           bgColor = 'black',
                           fgColor = 'white',
                           characters = ' .:-+*=%@#',
                           invert = true,
                           color = false,
                           resolution = 0.15
                       }) {
    // Reactive state
    const { size, gl, scene, camera } = useThree()

    // Create effect
    const effect = useMemo(() => {
        const effect = new AsciiEffect(gl, characters, { invert, color, resolution })
        effect.domElement.style.position = 'absolute'
        effect.domElement.style.top = '0px'
        effect.domElement.style.left = '0px'
        effect.domElement.style.pointerEvents = 'none'
        return effect
    }, [characters, invert, color, resolution])

    // Styling
    useLayoutEffect(() => {
        effect.domElement.style.color = fgColor
        effect.domElement.style.backgroundColor = bgColor
    }, [fgColor, bgColor])

    // Append on mount, remove on unmount
    useEffect(() => {
        gl.domElement.style.opacity = '0'
        gl.domElement.parentNode.appendChild(effect.domElement)
        return () => {
            gl.domElement.style.opacity = '1'
            gl.domElement.parentNode.removeChild(effect.domElement)
        }
    }, [effect])

    // Set size
    useEffect(() => {
        effect.setSize(size.width, size.height)
    }, [effect, size])

    // Take over render-loop (that is what the index is for)
    useFrame((state) => {
        effect.render(scene, camera)
    }, renderIndex)

    // This component returns nothing, it is a purely logical
}
