import React, {useState} from 'react';
import {Mesh, CylinderGeometry, MeshStandardMaterial, RingGeometry, Vector3} from 'three';
import {OrbitControls, Html, Text, Text3D, Box,CameraShake} from "@react-three/drei";
import {useFrame, useThree} from "@react-three/fiber";
import {useMemo} from "react";
import * as THREE from "three";
import CameraControls from "camera-controls";
CameraControls.install({ THREE })
import { Moon } from "lunarphase-js";
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { KernelSize } from 'postprocessing'

function getDaysArray (start, end) {
    let arr=[];
    let dt=new Date(start);
    for(; dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
};
function moondays ({ date } = { date: new Date() }) {
    // Moon's age, or Earth days since the last new moon, normalized within a 29.53059 Earth days calendar.
    const age = Moon.lunarAge(date);
    const agems = Moon.lunarAge(date) * 24 * 60 * 60 * 1000;
    const leftms = (29.53058770576 - age) * 24 * 60 * 60 * 1000;
    const phase = Moon.lunarPhase(date);

    const begin= new Date(date.getTime() - agems)
    const end= new Date(date.getTime() + leftms  )

    const beginDay = begin.getVarDate

    const days = getDaysArray(begin, end).map((day) => ({day, phase: Moon.lunarPhaseEmoji(day)}))
    return days;

    // return ({begin,end,age,phase,days })
}

function Controls({ zoom, focus, pos = new THREE.Vector3(), look = new THREE.Vector3() }) {
    const camera = useThree((state) => state.camera)
    const gl = useThree((state) => state.gl)
    const controls = useMemo(() => new CameraControls(camera, gl.domElement), [])
    return useFrame((state, delta) => {
        pos.set(focus.x, focus.y, focus.z + 2)
        look.set(focus.x, focus.y, focus.z - 2)

        // state.camera.position.lerp(look, 0.5)
        // state.camera.position.lerp(look, 0.5)
        // state.camera.updateProjectionMatrix()
        controls.moveTo( look.x, look.y, look.z, true)
        // look.y+=20
        controls.camera.lookAt( look)
        // controls.zoomTo(10,true)
        // console.log({controls})
        // controls.zoom( look.x, look.y, look.z, true)
        // controls.setLookAt(state.camera.position.x, state.camera.position.y, state.camera.position.z, look.x, look.y, look.z, true)
        //controls.setLookAt(state.camera.position.x, state.camera.position.y, state.camera.position.z, 0,0,0, true)
        return controls.update(delta)
    })
}

const InnerArc = ({ startAngle, endAngle, innerRadius, outerRadius, color, opacity }) => {
    const thetaStart = startAngle;
    const thetaLength = endAngle - startAngle;

    return (
        <mesh
            rotation={[0, 0, 0]}
            position={[0, 0, 0.1]}
            geometry={new RingGeometry(innerRadius, outerRadius, 32, 1, thetaStart, thetaLength)}
            material={new MeshStandardMaterial({ color, side: 2, transparent: true, opacity })}
        />
    );
};

const CircleSegment = ({ index, totalSegments, radius, color ,zoomToView, moonday}) => {
    const segmentAngle = (2 * Math.PI) / totalSegments;
    const currentAngle = index * segmentAngle;

    const x = Math.cos(currentAngle) * radius;
    const y = Math.sin(currentAngle) * radius;

    const rotationZ = currentAngle - Math.PI / 2;

    return (
        <group  position={[x, y, 0]}
                rotation={[0, 0, rotationZ]}
        >
        <mesh

            geometry={new RingGeometry(4, 10, 1, 1, 0,segmentAngle)}
            material={new MeshStandardMaterial({
                color ,
                outlineColor: 0x000000,
                side: 2,
                // wireframe: true,
            })}
            // onClick={(e) => {
            //     console.log(e.object)
            //     e.object.geometry.computeBoundingBox();
            //     const objPos = e.object.position
            //     const objRot = e.object.rotation.clone()
            //     const objGeoBoundingMax = e.object.geometry.boundingBox.max.clone()
            //
            //     const camPosition = objGeoBoundingMax
            //     camPosition
            //         .applyEuler(objRot).multiplyScalar(1) //new Vector3(1,1,0),segmentAngle)
            //         // .add(objGeoBoundingMax)
            //
            //
            //     console.log({objPos,objGeoBoundingMax,camPosition})
            //     console.log({objRot})
            //
            //     zoomToView(camPosition);
            //     // zoomToView();
            // }}
            onPointerEnter={ () => { document.body.style.cursor = 'pointer' } }
            onPointerLeave={ () => { document.body.style.cursor = 'default' } }
        />
            {/*<Text scale={0.1}> {moonday.day.toString()} </Text>*/}
            <Html
                position={[5.5,5.5,1.5]}
                distanceFactor={20}
            >{moonday.phase}</Html>
            <Html
                position={[5.5,5.5,2]}
                distanceFactor={12}
            ><p style={{ width: 150 }}>
                {moonday.day.toDateString().replace(/\d\d\d\d/g, '')}
            </p></Html>
            {/*<Text3D position={[2,2,5]} font={"Arial"}>{moonday.day.toDateString()}</Text3D>*/}
        </group>
    )
} //todo - need to calculate some kind of bounding box on this mesh so I can position other objects within it. turn it into a segment group or something

const Circle = ({ segments, radius,zoomToView, moondays }) => {

    const moonColorsLUT = createMoonColorLUT(moondays.length);
    const Segments = Array.from(moondays, (moonday, index) => (
        <CircleSegment
            key={index}
            index={index}
            totalSegments={moondays.length}
            radius={radius}
            // color={0xff7700}
            color={moonColorsLUT[index]}
            outlineColor={0x000000}
            zoomToView={zoomToView}
            moonday={moonday}
        />
    ))

    return (
        <>
            {Segments}
            <InnerArc startAngle={0} endAngle={Math.PI / 4} innerRadius={6.2} outerRadius={6.5} color={0xff0000} opacity={0.5} />
            {/*<InnerArc startAngle={Math.PI / 2} endAngle={Math.PI} innerRadius={8.2} outerRadius={8.5} color={0x00ff00} opacity={0.8} />*/}
            {/*<InnerArc startAngle={Math.PI} endAngle={(3 * Math.PI) / 2} innerRadius={7.8} outerRadius={7.9}  color={0x0000ff} opacity={0.5} />*/}
            {/*<InnerArc startAngle={(3 * Math.PI) / 2} endAngle={2 * Math.PI} innerRadius={7.5} outerRadius={7.8} color={0xffff00} opacity={0.5} />*/}
        </>
    )
}

const CircleTimeTest = ({position}) => {
    const [zoom, setZoom] = useState(false)
    const [focus, setFocus] = useState(new Vector3( 0, 0, 30 ))

    const zoomToView= (focusRef) => {
        console.log({focusRef})
        setZoom(!zoom)
        setFocus(focusRef)
        // console.log(moonage())
    }
    const date = new Date();
    date.setDate(date.getDate()+1)
    const moondays1 = moondays({date});
    return (
        <>
            <OrbitControls makeDefault zoom={zoom} focus={focus} />
            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            {/*<Controls zoom={zoom} focus={focus} />*/}
            <group position={position}>
            <Circle moondays={moondays1} segments={28} radius={0.5} zoomToView={zoomToView}/>
                {/*<mesh onPointerOver={() => set(true)} onPointerOut={() => set(false)}>*/}
                {/*    <torusKnotGeometry args={[10, 3, 128, 32]} />*/}
                {/*    <meshBasicMaterial color={ 'hotpink'} />*/}
                {/*</mesh>*/}
            </group>
            {/*<EffectComposer multisampling={8}>*/}
            {/*    <Bloom kernelSize={3} luminanceThreshold={0} luminanceSmoothing={0.4} intensity={0.6} />*/}
            {/*    <Bloom kernelSize={KernelSize.HUGE} luminanceThreshold={0} luminanceSmoothing={0} intensity={0.5} />*/}
            {/*</EffectComposer>*/}
        </>
    );
}

export { CircleTimeTest}


function interpolateColor(color1, color2, factor) {
    const r = color1.r + (color2.r - color1.r) * factor;
    const g = color1.g + (color2.g - color1.g) * factor;
    const b = color1.b + (color2.b - color1.b) * factor;
    return { r, g, b };
}

function rgbToHex(color) {
    const r = Math.round(color.r).toString(16).padStart(2, '0');
    const g = Math.round(color.g).toString(16).padStart(2, '0');
    const b = Math.round(color.b).toString(16).padStart(2, '0');
    return `#${r}${g}${b}`;
}

function createMoonColorLUT(steps) {
    const fullMoonColor = { r: 255, g: 165, b: 0 }; // 0xff7700
    const newMoonColor = { r: 220, g: 180, b: 125 };
    const lut = [];

    for (let i = 0; i < steps; i++) {
        const factor = i / (steps - 1);
        const interpolatedColor = interpolateColor(fullMoonColor, newMoonColor, factor);
        lut.push(rgbToHex(interpolatedColor));
    }

    return lut;
}

